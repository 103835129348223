import { IConfig } from "../interfaces/ConfigInterface";

export const config: IConfig = {
  locationName: "BARbarossa",
  locationStreet: "Frankfurter Straße 49",
  locationCity: "Gelnhausen",
  locationPLZ: 63571,
  locationPhone: "06051 / 9679333",
  locationInstagram: "https://www.instagram.com/barbarossa_hookahlounge",
  categoriesHeadline: "Kategorien",
  searchHeadline: "Suchen",
  searchPlaceholder: "Ich suche nach ...",
  newsHeadline: "News",
  adminLink: "https://app.contentful.com",
  options: {
    locationHasNewsSlider: true,
    locationHasEventSlider: false,
    locationHasJobSection: false,
  },
};
